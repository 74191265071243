import * as React from 'react'
import { withPrismicPreviewResolver, usePrismicPreviewContext, usePrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import {linkResolver} from "../utils/linkResolver";

const PreviewPage = () => {
    const params = usePrismicPreviewContext();
    //const resolver = usePrismicPreviewResolver();
    console.log(params);
    //resolver().then(res => console.log(res)).catch(err => console.log(err));
  return (
    <div>
      <h1>Loading preview…</h1>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: "usatrt-ua",
    linkResolver,
  },
]);
